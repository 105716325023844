.index {
	$size: 7px;
	width: $size;
	height: $size;
	border-radius: 50%;
	margin-right: 10px;
}

.green {
	background-color: rgb(33, 182, 33);
}

.yellow {
	background-color: yellow;
}

.red {
	background-color: red;
}
