@import "~bootstrap/dist/css/bootstrap";
// General theme contents
@import "partials/variables";
@import "partials/general";
@import "partials/generic";
@import "partials/mixins/function";

// important Element
@import "partials/menu/menu-lite";
@import "partials/widget/widget";

// Theme Element
@import "partials/theme-elements/form";
@import "partials/theme-elements/radiobox-checkbox";
@import "partials/theme-elements/labels-badges";
@import "partials/theme-elements/data-tables";
@import "partials/theme-elements/authentication";
@import "partials/theme-elements/button";
@import "partials/theme-elements/alert";
@import "partials/theme-elements/breadcrumb-pagination";
@import "partials/theme-elements/progress";
@import "partials/theme-elements/tooltip";
@import "partials/theme-elements/popover";
@import "partials/theme-elements/nav";
@import "partials/theme-elements/modal";

// Other
@import "partials/other/chat";
@import "partials/other/prism";
@import "partials/other/switches";

@import "partials/other/chart";
@import "partials/other/icon-lauouts";

@import "partials/third-party/third-party";
